import { action, computed, observable, reaction, toJS, IObservableArray, ObservableMap, makeObservable, runInAction } from 'mobx'

import groups from 'store/groups.store'
import userStore from 'store/user.store'
import api from 'service/api'
import { LastMessage, Message, User, UserMessage } from 'utils/models'
import { ID, StrategyType, SurveyAnswer, StatusType, MessageDialogMode, MessageResponseOp, PayloadRequest } from 'utils/types'
import { LOADERS } from 'utils/loaders'
import { isAfter, parseISO, startOfDay } from 'date-fns'
import user from 'store/user.store'
import veryLocalStorage, { storageKeys } from 'utils/vls'
import { OperationType } from '../utils/operation'

const IMAGE_MAX_HEIGHT_AND_WIDTH = 800
const MAX_FILE_SIZE_MB = 2
export interface CommunityResponse {
  community_id: number
  community_name: string
  message_id: number
  multiMessageID: number
  received: number
  total: number
}
export interface MessageDetails {
  multiMessageID: number,
  message_id: number,
  community_id: number,
  community_name: string,
  total: number,
  received: number
}

class MessagesStore {

  constructor() {
    makeObservable(this, {
      messages: observable,
      messageResponses: observable,
      isLoading: observable,
      viewerModalOpen: observable,
      viewerModalFile: observable,
      activeTooltip: observable,
      messageFeedSections: computed,
      setActiveTooltip: action,
      setViewerModalOpen: action,
      setViewerModalFile: action,
      setIsLoading: action,
      getMessages: action,
      updateMessage: action,
    })
    reaction(() => (Boolean(userStore.jwt && userStore.isAuth)), (canGetMessages: boolean) => {
      if (canGetMessages) {
        this.getMessages()
      }
    }, { fireImmediately: true })
  }
  messages: IObservableArray<Message> = observable([])
  messageResponses = new ObservableMap<number, CommunityResponse[]>()
  activeTooltip: number | null = null;
  isLoading = true
  setActiveTooltip = (id: number | null) => {
    if (this.activeTooltip === id) {
      this.activeTooltip = null
    } else {
      this.activeTooltip = id
    }
  }

  viewerModalOpen = false
  setViewerModalOpen = (state: boolean) => this.viewerModalOpen = state
  viewerModalFile: { type: string | null, file: any } = { type: null, file: null }
  setViewerModalFile = (state: { type: string, file: any }) => this.viewerModalFile = state

  setIsLoading = (bool: boolean) => {
    this.isLoading = bool;
  }
  get messageFeedSections() {
    // accumulate sections and dates
    let sections: Map<number, Message[]> = new Map()
    this.messages?.forEach((message: Message) => {
      // @ts-ignore
      let sectionDate = startOfDay(parseISO(message.created)).getTime()
      if (isNaN(sectionDate)) {
        sectionDate = startOfDay(parseISO(message.created.toISOString())).getTime()
      }
      const section = sections.get(sectionDate)
      section ? section.push(message) : sections.set(sectionDate, [message])
    })
    // sort dates
    const sortedKeys = Array.from(sections.keys()).sort((a: number, b: number) => b - a)
    // add relevant dates to each sorted date
    const sortedSections: Map<number, Message[]> = new Map()
    sortedKeys.forEach((key: number) => {
      const section = sections.get(key)
      // @ts-ignore
      const sortedMessages: Message[] = section ? section.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()) : []
      sortedSections.set(key, sortedMessages)
    })
    const result: { date: number, messages: Message[] }[] = []
    Array.from(sortedSections.entries()).forEach(el => {
      result.push({ date: el[0], messages: el[1] })
    })
    return (result)
  }
  openViewerModal = (type: string, file: any) => {
    if (!type && !file) {
      return
    }
    this.setViewerModalFile({ type, file })
    this.setViewerModalOpen(true)
    // this.setIsLoading(true)
  }

  closeViewerModal = () => {
    this.setViewerModalOpen(false)
  }
  getMessages = async () => {
    const res = await api.getMessages()
    runInAction(() => {
      this.messages.replace(res)
      this.setIsLoading(false)
    })
  }

  //not an action, used in useeffect
  getMessageDetails = async (messageId: number): Promise<CommunityResponse[]> => {
    const res = await api.getMessageDetails(messageId)
    if (!res.success) {
      return []
    }
    return res.data
  }

  //currently only works on client side messages (for example when message failed and u send it again) because of createguid usage
  updateMessage = async (message: Message) => {
    const foundIndex = this.messages.findIndex(el => el.create_guid === message.create_guid)
    if (foundIndex != -1) {
      this.messages[foundIndex] = message
    }
    else {
      this.messages.push(message)
    }
  }
  updateMessageDetails = async (message: Message, total: number = 0, received: number = 0) => {
    const foundIndex = this.messages.findIndex(el => ((el.create_guid && message.create_guid) && (el.create_guid === message.create_guid))
      || (el.id === message.id)
    )
    message.received = received
    message.total = total
    if (foundIndex != -1) {
      this.messages[foundIndex] = message
    }
    else {
      this.messages.push(message)
    }
  }
}

const messages = new MessagesStore()
export default messages
