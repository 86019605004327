import React, {FC} from 'react'
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import Loading from 'core/Loading'

const VideoViewer: FC<any> = ({ fileUrl, thumbnail, fileType, widthClass, padding = 0 }) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);

  const [loading, setLoading] = React.useState(true)

  const onReady = (player) => {
    playerRef.current = player;

    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });
  }

  const initPlayer = (fType) => {
    if (!videoRef.current) {
      return;
    }

    const options = {
      autoplay: false,
      controls: true,
      enableSmoothSeeking: true,
      sources: [{
        src: fileUrl,
        type: fType
      }]
    }

    if (thumbnail) {
      //options['poster'] = thumbnail
    }

    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      const player = playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        onReady && onReady(player);

        const resizeVideo = () => {
          const box = document.querySelector(widthClass);
          
          if (box && player.el()) {
            const dem = getComputedStyle(player.el())

            const boxWidth = parseInt(box.offsetWidth)
            const boxHeight = parseInt(box.offsetHeight)

            let videoWidth = parseInt(dem.width)
            let videoHeight = parseInt(dem.height) 

            if (isNaN(boxWidth) || isNaN(boxHeight) || isNaN(videoWidth) || isNaN(videoHeight)) {
              return 
            }

            if (videoWidth > boxWidth) {
              videoWidth = boxWidth
              videoHeight = Math.round(videoWidth * boxHeight / boxWidth)
            }

            if (videoHeight > boxHeight) {
              videoHeight = boxHeight
              videoWidth = Math.round(videoHeight * boxWidth / boxHeight)
            }

            player.width(videoWidth);
            player.height(videoHeight);
            setLoading(false)
          }
        }

        player.on('resize', (e) => {
          setTimeout(resizeVideo, 0);
        });
      });


      player.addClass('vjs-matrix');

    // You could update an existing player in the `else` block here
    // on prop change, for example:
    } else {
      const player = playerRef.current;
      player.poster(thumbnail);
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }

  React.useEffect(() => {
    if (!fileType && fileUrl.includes("blob:")) {
      return;
    }

   if (!fileType) {
    fetch(fileUrl, {
      method: 'HEAD'
    }).then(reponse => {
      const sourceVideoType = reponse.headers.get('Content-Type');

      if (sourceVideoType) {
        initPlayer(sourceVideoType.replace("/*", "/mp4"))
      } 
    })  
   } else {
    initPlayer(fileType)
   }
  }, [fileUrl, fileType, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);


  return (
  <> 
    {loading && <div style={{ position: 'absolute' }}><Loading/></div>}
    <div style={{ opacity: loading ? 0 : 1, display: 'flex', justifyContent: 'center', background: 'black' }} data-vjs-player>
      <div ref={videoRef} />
    </div>
  </>)
}

export default VideoViewer;
